<template>
  <div>
<!--    <div v-if="datalist.title!=null">
      <van-row>
        <van-col span="16" offset="1" class="mt4">{{ datalist.title }}</van-col>
      </van-row>
      <div class="line"></div>
      <div v-for="(item,key) in datalist.groups" :key="key" class="mt20">
        <van-row>
          <van-col span="23" offset="1" class="mb10">{{ item.title }}
            <span v-if="item.type == 'list'" class="ml15" @click="changeshow"><van-icon name="arrow-down" color="#e5e5e5" size="14"/></span>
          </van-col>
        </van-row>
        <van-row v-if="item.type == 'data'">
          <van-col :span="22" offset="1" class="mb11 ml6v">
            <div v-for="(items,keys) in item.fields" :key="keys">
              <van-row>
                <van-col :span="24"><span style="color: #7f7f7f;">{{ items.title }}</span></van-col>
              </van-row>
              <van-row>
                <van-col :span="24">
                  <van-cell-group>
                    <div v-if="items.type=='select'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''"
                                :value="datainfo[items.name].label.nickName!=undefined?datainfo[items.name].label.nickName+' '+datainfo[items.name].label.phoneNum:datainfo[items.name].label"/>
                      <van-cell v-else/>
                    </div>
                    <div v-else-if="items.type=='mutiselect'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''">
                        <template #default>
                          <span v-for="(i,ke) in datainfo[items.name]" :key="ke">{{ i.label }}&nbsp;</span>
                        </template>
                      </van-cell>
                      <van-cell v-else/>
                    </div>
                    <div v-else-if="items.type=='contacts'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''"
                                :value="datainfo[items.name].nickName +' '+ datainfo[items.name].phoneNum">
                      </van-cell>
                      <van-cell v-else/>
                    </div>
                    <div v-else-if="items.type == 'img'||items.type == 'file'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''">
                        <template #extra>
                          <van-image width="3rem" height="4rem" v-for="(it,k) in datainfo[items.name].list" :key="k"
                                     :src="it" class="mr5" @click="showimg(datainfo[items.name].list)">
                            <template v-slot:loading>
                              <van-loading type="spinner" size="16"/>
                            </template>
                          </van-image>
                        </template>
                      </van-cell>
                      <van-cell v-else>
                        &lt;!&ndash;                     <template #extra>
                                               <van-image width="3rem" height="3rem" v-for="(it,k) in img" :key="k" :src="it" @click="showimg(img)">
                                                 <template v-slot:loading>
                                                   <van-loading type="spinner" size="16" />
                                                 </template>
                                               </van-image>
                                             </template>&ndash;&gt;
                      </van-cell>
                    </div>
                    <div v-else>
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''"
                                :value="datainfo[items.name]"></van-cell>
                      <van-cell v-else/>
                    </div>
                  </van-cell-group>
                </van-col>
              </van-row>
            </div>
          </van-col>
        </van-row>
        <van-row v-if="item.type == 'list' && show == true" class="mb11 mt5">
          <van-col span="22" offset="1" class=" ml6v" v-for="(it,ke) in datainfo[item.name]" :key="ke">
            <div v-for="(items,keys) in item.fields" :key="keys">
              <p class="mb5 mt5">
                <span style="color: #7f7f7f;" class="mb7">{{ items.title }}：</span>
                <span class="font14 col32" v-if="items.type == 'mutiselect'">
                <span v-for="(i,k) in it[itms.name]" :key="k">{{ i.label }}</span>
              </span>
                <span class="font14 col32" v-else-if="items.type == 'select'" >
                  {{ it[items.name].label }}
&lt;!&ndash;                  {{ it[items.name].label.nickName !=undefined? it[items.name].label.nickName: it[items.name].label}}&ndash;&gt;
                </span>
                <span v-else-if="items.type == 'contacts'">{{ datainfo[items.name].nickName +' '+ datainfo[items.name].phoneNum }}</span>
                <span class="font14 col32" v-else-if="items.type == 'img'">
                <van-image width="3rem" height="4rem" v-for="(itt,k) in it[items.name].list" :key="k" :src="itt"
                           class="mr5" @click="showimg(it[items.name].list)">
                    <template v-slot:loading>
                      <van-loading type="spinner" size="16"/>
                    </template>
                  </van-image>
              </span>
                <span class="font14 col32" v-else>{{ it[items.name] }}</span>
              </p>
            </div>
            <div class="line1"></div>
          </van-col>
        </van-row>
        <div class="line"></div>
      </div>
    </div>-->
    <info-display :datalist="datalist" :datainfo="datainfo"></info-display>
  </div>
</template>

<script>
import qs from 'qs';
import {getShareData} from "@/api/api";
import { Toast} from "vant";
import infoDisplay from "@/components/infoDisplay";

export default {
  name: "share",
  data() {
    return {
      imgUrl: "http://bullpm-1253367923.cos.ap-chengdu.myqcloud.com",
      imgCompress: "?imageMogr2/thumbnail/!70p",
      id: "",
      sign: "",
      datalist: [],
      datainfo: {},
      companyId: "",
      show: true,
    }
  },
  mounted() {
    this.getCode();
  },
  components: {
    infoDisplay,
  },
  methods: {
    getCode() {
      /*let href = "https://sixu.work/pubshare?id=1fb630cd508a42fe9d27f7aeaacd0847&sign=887385db75dffc77290a32693341fa32"
      if (href.indexOf("id") != -1) {
        this.id = qs.parse(href.split("#")[0].split("?")[1]).id;
        this.sign = qs.parse(href.split("#")[0].split("?")[1]).sign;
      }*/
      if (window.location.href.indexOf("id") != -1){
        this.id = qs.parse(window.location.href.split("#")[0].split("?")[1]).id;
        this.sign = qs.parse(window.location.href.split("#")[0].split("?")[1]).sign
      }
      if (this.id) {
        getShareData(this.id).then(res => {
          //console.log("share",res);
          if (res != null && res.data != null && res.data.content != null) {
            //console.log('contents', res);
            let contents = res.data.content;
            this.companyId = contents.form.companyId
            this.datalist = JSON.parse(contents.form.formDef);
            //console.log('form', this.datalist);
            if (contents.data != null && contents.data != '') {
              this.datainfo = contents.data;
              //console.log('datainfo', this.datainfo);
            }
            for (let i in this.datalist.groups) {
              let item = this.datalist.groups[i];
              //item.show = true;
              for (let j in item.fields) {
                let items = item.fields[j];
                if (items.type == 'img') {//当有img时，会组装img路径如下所示
                  if (this.datainfo[items.name] != null && this.datainfo[items.name] != '') {
                    this.datainfo[items.name].list = [];
                    for (let k in this.datainfo[items.name]) {
                      let d = this.datainfo[items.name][k];
                      if (d.fileName != null && d.fileName != '') {
                        this.datainfo[items.name].list.push(this.imgUrl + "/" + this.companyId + "/" + d.fileName + this.imgCompress);
                      }
                    }
                  }
                }
                if (items.type == 'file') {
                  if (this.datainfo[items.name] != null && this.datainfo[items.name] != '') {
                    this.datainfo[items.name].list = [];
                    for (let k in this.datainfo[items.name]) {
                      let d = this.datainfo[items.name][k];
                      if (d.fileName != null && d.fileName != '') {
                        this.datainfo[items.name].list.push(this.imgUrl + "/" + this.companyId + "/" + d.fileName);
                      }
                    }
                  }
                }
              }

            }
            //console.log('form', this.datalist);
          } else {
            Toast.fail(res.data.errorMsg);
          }
        })
      }
    },

  }
}
</script>

<style scoped>
.font14 {font-size: 14px;}
.col32 {color: #323233;}
.mcenter{text-align: center;}
.mt1 {margin-top: 5px;}
.mb1 {margin-bottom: 5px;}
.mt3 {margin-top: 3vh;}
.mt4 {margin-top: 4vh;}
.mt5 {margin-top: 5px;}
.mt20 {margin-top: 2vh;}  .ml5 {margin-left: 5px;}
.ml6v {margin-left: 6vw;}
.ml15 {margin-left: 15px;}
.mb5 {margin-bottom: 5px;}
.mb7 {margin-bottom: 7px;}
.mt15 {margin-top: 15px;}
.mb11 {margin-bottom: 11px;}
.mb10 {margin-bottom: 2vh;}
.line1 {
  border-top: #f2f2f2 solid 1px;
  width: 92vw;
  margin: 7px auto;
}
.line {border-top: #f2f2f2 solid 1px;width: 94vw;margin: 20px auto;}
.mr {margin-right: 18vw;}
.mr5 {margin-right: 5px;}
.mr10 {margin-right: 10px;}
.mright {text-align: right;}
.btn {text-align: center;margin: 4vh auto;}
.txt {vertical-align: middle;}
.van-cell {padding: 10px 0 !important;}
</style>